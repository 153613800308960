import { Container, Box, Typography, Grid, useMediaQuery } from '@mui/material'
import React, { useEffect } from 'react'
import { colors } from '../../pages/colors'
import Footer from '../../components/Footer/Footer'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const apoiadores = [
  {
    nome: 'Circuito das Águas Paulista',
    img: 'https://firebasestorage.googleapis.com/v0/b/igcachaca.appspot.com/o/imagens%2Fcircuito-paulista.png?alt=media&token=2ae8c549-74f2-4f8b-bbda-9fff922349a1'
  },
  {
    nome: 'Mapa',
    img: 'https://firebasestorage.googleapis.com/v0/b/igcachaca.appspot.com/o/imagens%2Fmapa.png?alt=media&token=01d9d42f-88ac-4569-8fa6-b2692d6f1774'
  },
  {
    nome: 'Serra Negra',
    img: 'https://firebasestorage.googleapis.com/v0/b/igcachaca.appspot.com/o/imagens%2Fserra-negra.jpg?alt=media&token=ac80558f-90e5-41cf-ba38-e28ee56e567f'
  },
  {
    nome: 'Sebrae',
    img: 'https://firebasestorage.googleapis.com/v0/b/igcachaca.appspot.com/o/imagens%2Fsebrae-logo-1.png?alt=media&token=079e65d8-0d38-40b3-8e10-e063724a2fe8'
  },
  {
    nome: 'ADCAP',
    img: 'https://firebasestorage.googleapis.com/v0/b/igcachaca.appspot.com/o/imagens%2FADCAP.jpg?alt=media&token=518d4e08-c1a8-4c13-bd1f-c41928cbff59'
  },
  {
    nome: 'Amparo',
    img: 'https://firebasestorage.googleapis.com/v0/b/igcachaca.appspot.com/o/imagens%2Famparo.png?alt=media&token=cae68df8-86d4-4b1f-af98-57bf9b1fe906'
  },
]

export default function Festival() {
  const matches = useMediaQuery('(min-width:600px)');


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Box sx={{
        backgroundColor: '#EE771E'
      }}>
        <Container maxWidth='xl' >
          <Box sx={{
            padding: '72px 0',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '32px'
          }} >
            {/**  <h2 className='bold' style={{ textAlign: 'center', color: '#20244B', backgroundColor: colors.main_white, padding: '9px 16px' }}>
              8, 19 e 20 de Outubro
            </h2>
            */}

            <Box>
              <h1 className='bold white'>
                Festival da Cachaça do Estado de São Paulo
              </h1>
              <h3 className='regular white'>
                Venha aprender, degustar e celebrar o espírito que é essencialmente brasileiro.
              </h3>
            </Box>

            <Box sx={{
              alignSelf: 'flex-start',
            }}>
              <h3 className='medium white'>
                Apoiadores
              </h3>

            </Box>
            <Grid container columnSpacing={10} rowSpacing={4} justifyContent={matches ? 'start' : 'center'} >

              {matches ? (
                <>

                  {apoiadores.map((apoiador, index) => (
                    <Grid item >
                      <img src={apoiador.img} alt={apoiador.nome} style={{ width: '160px', height: '140px' }} />
                    </Grid>
                  ))}


                </>

              ) : (
                <>

                  <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                      delay: 3500,
                      disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}            
                  >
                    {apoiadores.map((apoiador, index) => (
                      <SwiperSlide key={index} style={{ margin: 0, padding: 0, justifySelf: 'center' }}>
                        <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', justifySelf:'center',marginTop:'28px',
                             
                      }}>
                          <img src={apoiador.img} alt={apoiador.nome} style={{ width: '250px', height: '150px', display: 'block',padding:'0 px', marginLeft:'68px' }} />
                        </Grid>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </>
              )}

            </Grid>


          </Box>
        </Container>
      </Box>

      <Box sx={{
        backgroundColor: colors.main_white,
        padding: '100px 0'
      }}>
        <Container maxWidth='xl' >

          <Grid container spacing={2} columnSpacing={'50px'} >
            <Grid item xs={12} sm={6} >
              <h1 className='bold' >
                A história por trás do principal Festival da Cachaça do estado de São Paulo: cultura, tradição e qualidade
              </h1>
              <h3 className='regular' style={{ marginTop: '40px' }} >
                Nossa história começou em uma roda de amigos degustando cachaça no Bar Seo Bastião, no centro de Amparo. Depois de algumas cachaças, surgiu a ideia de reunir alguns produtores, amigos e familiares com o propósito de fazer um evento para divulgar e valorizar a tradição da cachaça de alambique, gerar emprego e renda para a região.
                <br />
                <br />
              </h3>
              <h3 className='regular' >
                Assim, dia 8 de agosto de 2017, nasceu o Festival da Cachaça do Circuito das Águas Paulista. Hoje o festival é o principal evento de cachaça do estado de São Paulo e atrai milhares de visitantes e apreciadores de cachaça de excelente qualidade. Além disso, emprega diretamente muitas pessoas durante e depois do festival, incluindo organizadores, fornecedores, guias turísticos e também beneficia hotéis, restaurantes, bares e o comércio local.
                <br />
                <br />
              </h3>
              <h3 className='regular'>
                O festival da cachaça preserva a cultura, tradição, qualidade e busca valorizar também os métodos de produção, embora tantos rótulos, nenhuma cachaça é igual a outra, a bebida sempre traz o modo de fazer de cada produtor, além de outros fatores  naturais como solo, clima, água e relevo que definem o terroir característico da região do Circuito das Águas Paulista.
                <br />
                <br />
              </h3>
              <h3 className='regular'>
                Se você é iniciante ou colecionador da bebida, o Festival da Cachaça do Circuito das Águas Paulista é um evento imperdível. Você encontrará inúmeras oportunidades para aprender, degustar e celebrar o espírito que é essencialmente brasileiro.
              </h3>
            </Grid>
            <Grid item xs={12} sm={6} sx={{
              display: 'flex',
              justifyContent: 'center',

            }} >
              <img src={'https://firebasestorage.googleapis.com/v0/b/igcachaca.appspot.com/o/imagens%2Ffestival_colorido.png?alt=media&token=1f859a08-9c65-48cb-bc57-969a15cef984'} alt='festival' style={{ width: '220px', height: '200px', marginTop: matches ? '0' : '40px' }} />
            </Grid>
          </Grid>

          {/** IMAGENS */}

          <Grid container spacing={'40px'} mt={12}>
            <Grid item sm={12} lg={8}>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '40px',

              }}>

                <img src={'https://firebasestorage.googleapis.com/v0/b/igcachaca.appspot.com/o/imagens%2Ffestival-1.jpg?alt=media&token=455cd81b-6a0c-4c72-b551-4c7b992a24e4'} alt='Festival da cachaça' width={'100%'} height={matches ? '400px' : '100%'} style={{ objectFit: 'cover' }} />

                <Box sx={{
                  display: 'flex',
                  gap: '40px',
                  flexDirection: matches ? 'row' : 'column'

                }}>
                  <img src={'https://firebasestorage.googleapis.com/v0/b/igcachaca.appspot.com/o/imagens%2Ffestival-3.jpg?alt=media&token=a29690ea-92b7-48c8-8d41-372e0f65f441'} alt='Festival da cachaça' width={matches ? '48%' : '100%'} height={'350px'} style={{ objectFit: 'cover' }} />

                  <img src={'https://firebasestorage.googleapis.com/v0/b/igcachaca.appspot.com/o/imagens%2Ffestival-4.jpg?alt=media&token=990e5f97-3ce1-49cb-ab88-77a15e350555'} alt='Festival da cachaça' width={matches ? '48%' : '100%'} height={'350px'} style={{ objectFit: 'cover' }} />
                </Box>

              </Box>

            </Grid>
            <Grid item sm={12} lg={4}>
              <img src={'https://firebasestorage.googleapis.com/v0/b/igcachaca.appspot.com/o/imagens%2Ffestival-2.jpg?alt=media&token=86a580bb-55b9-48ee-808a-0142a325df3f'} alt='Festival da cachaça' width={'100%'} height={'100%'} style={{ objectFit: 'cover' }} />
            </Grid>

          </Grid>

        </Container >
      </Box>



      <Footer />


    </>
  )
}
