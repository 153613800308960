import React, { useEffect, useRef, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Grid, Typography, useMediaQuery } from '@mui/material';
import { colors } from '../colors';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Styles.css'
import { getMembros, } from '../../features/admin/adminSlice';
import { AiOutlineInstagram, AiOutlineWhatsApp } from 'react-icons/ai';
import Footer from '../../components/Footer/Footer';
import { BsArrowUpRight, } from 'react-icons/bs';
import { BiMinus, BiPlus } from 'react-icons/bi';
import ProdutoresPagination from '../../components/Pagination/Produtores';

export default function QuemSomos() {

    const dispatch = useDispatch();
    const { membros } = useSelector(state => state.admin)
    const [produtores, setProdutores] = useState([])

    const location = useLocation();
    const lastHash = useRef('');

    const navigate = useNavigate()

    const matches = useMediaQuery('(min-width:600px)')
    const matchesNotebook = useMediaQuery('(min-width:1200px)')

    const [expanded, setExpanded] = useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const [hoveredPerson, setHoveredPerson] = useState(null);


    useEffect(() => {

        dispatch(getMembros())

    }
        , [dispatch])

    useEffect(() => {
        if (location.hash) {
            lastHash.current = location.hash.slice(1);
        }

        if (lastHash.current && document.getElementById(lastHash.current)) {
            setTimeout(() => {
                document
                    .getElementById(lastHash.current)
                    ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                lastHash.current = '';
            }, 100);
        }
    }, [location]);



    return (
        <>
            <Box id='associacao' sx={{
                backgroundColor: colors.main_blue,
                padding: '80px 0 150px 0',


            }}>
                <Container maxWidth='xl'>
                    <Grid container rowSpacing={5} pb={'100px'} >
                        <Grid item xs={12} lg={6}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '40px',

                            }}>
                                <h1 className='white bold'>
                                    Um Olhar Mais Profundo sobre a Associação e suas Contribuições
                                </h1>
                                <h4 className='white regular'>
                                    A APCCAP, em colaboração com MAPA, IFSP, SEBRAE, APTA e SENAR, promove a cachaça do Circuito das Águas Paulista por meio de reuniões, palestras e apoio financeiro a eventos. Este esforço conjunto fortalece a qualidade e reconhecimento nacional e internacional da bebida.

                                    <br />
                                    <br />

                                    As famílias da região, há gerações, preservam a tradição na produção de cachaça, buscando agora a Indicação Geográfica. A união à APCCAP não só oferece meios de negócio, mas também impulsiona oportunidades econômicas, gerando emprego e renda e valorizando a autenticidade da cachaça brasileira na região.
                                </h4>

                            </Box>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: matchesNotebook ? 'flex-end' : 'center',
                            }}>
                                <img src={'https://firebasestorage.googleapis.com/v0/b/igcachaca.appspot.com/o/imagens%2Fassocia%C3%A7%C3%A3o.jpg?alt=media&token=afd70eba-d974-4001-a085-ec0df3e1fded'} alt='imagem' width={matches ? 395 : '95%'} />
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container columnSpacing={2} rowSpacing={4} >
                        {membros?.length > 0 ? membros
                            .slice(0, 3)
                            .map((membro, index) => (
                                <Grid
                                    key={membro._id}
                                    item
                                    xs={12}
                                    md={6}
                                    lg={4}
                                    onMouseEnter={() => setHoveredPerson(membro)}
                                    onMouseLeave={() => setHoveredPerson(null)}
                                >
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        gap: '16px',
                                        position: 'relative',

                                    }}>

                                        {hoveredPerson?._id === membro._id ? (
                                            <>
                                                <img
                                                    src={membro?.vice?.profilePhoto || 'https://placehold.co/600x400'}
                                                    alt='imagem'
                                                    width={matches ? '56%' : '55%'}
                                                    height={matches ? 286 : 260}
                                                    style={{
                                                        objectFit: 'cover',
                                                    }}
                                                />



                                                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                                    <h3 className='white medium'>
                                                        Vice
                                                    </h3>
                                                    <h4 className='white regular'>
                                                        {membro?.vice?.name.split(' ')[0]} {membro.vice.name.split(' ')[membro.vice.name.split(' ').length - 1]}
                                                    </h4>
                                                </div>


                                            </>
                                        )

                                            : (<>

                                                <img src={membro?.dados_pessoais?.profilePhoto || 'https://placehold.co/600x400'} alt='imagem' width={matches ? '56%' : '55%'} height={matches ? 286 : 260} style={{
                                                    objectFit: 'cover'

                                                }} />



                                                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                                    <h3 className='white medium'>
                                                        {membro?.role === 'secretario' ? 'Secratário' : membro.role.charAt(0).toUpperCase() + membro.role.slice(1)}
                                                    </h3>
                                                    <h4 className='white regular'>
                                                        {membro?.dados_pessoais?.name.split(' ')[0]} {membro.dados_pessoais.name.split(' ')[membro.dados_pessoais.name.split(' ').length - 1]}
                                                    </h4>
                                                </div>
                                            </>)}






                                    </Box>
                                </Grid>

                            )) :
                            <Grid item xs={12} lg={6}>
                                <h2 className='white'>
                                    Membros da associção ainda não cadastrados
                                </h2>
                            </Grid>
                        }
                    </Grid>



                </Container>


            </Box>


            <Box sx={{
                backgroundColor: colors.main_white,
                position: 'relative',
            }}>

                <div className="custom-shape-divider-top-1697630840">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200 0L0 0 598.97 114.72 1200 0z" className="shape-fill"></path>
                    </svg>
                </div>


                <Container id='produtores' maxWidth='xl'>

                    <Grid container rowSpacing={5} pt={10} pb={10} columnGap={'43px'} >
                        <Grid item xs={12}>
                            <h3 className='semi-bold'
                                style={{
                                    padding: '0 0 16px 0',
                                    fontWeight: 550, color: colors.main_blue_dark, borderBottom: `3px solid ${colors.main_blue_dark}`, width: !matches ? '100%' : '280px',
                                    textAlign: matches ? 'left' : 'center'
                                }} >
                                Produtores em Amparo
                            </h3>
                        </Grid>

                        {produtores?.amparo?.length > 0 ? produtores?.amparo?.map((produtor, index) => (
                            <Grid key={index} item xs={12} lg={3} xl={2} >
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',

                                    border: `1px solid ${colors.main_blue_dark}`,
                                }}>

                                    <img src={produtor?.marca?.logo} alt='Logo da marca' width={matches ? '100%' : '100%'} height={matches ? 286 : 260} style={{
                                        objectFit: 'cover'

                                    }} />


                                    <Box sx={{
                                        backgroundColor: colors.main_blue_dark,
                                        padding: '24px 12px',
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}>
                                        <div>
                                            <a target='_blank' rel="noreferrer" href={produtor?.marca?.site ? `https://${produtor?.marca?.site} ` : `https://www.google.com/maps/dir//${produtor?.propriedade?.logradouro_propriedade}%20-%20${produtor?.propriedade?.cidade_propriedade},%20${produtor?.propriedade?.estado_propriedade},%20${produtor?.propriedade?.cep_propriedade}/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x94c6c6b0a0a0a0a7:0x1b0b0b0b0b0b0b0b?sa=X&ved=2ahUKEwjJ6Z7X2Z7zAhVYIbkGHXZrDZIQ9RcwDHoECBQQBQ`} style={{ textDecorationColor: colors.main_white }}>
                                                <h4 className='white semi-bold'>
                                                    {produtor.propriedade.nome_propriedade.length > 15 ? produtor.propriedade.nome_propriedade.slice(0, 15) + '...' : produtor.propriedade.nome_propriedade}
                                                </h4>
                                            </a>
                                        </div>

                                        <Box sx={{
                                            display: 'flex',
                                            gap: '3px',
                                        }}>
                                            <a href={`https://api.whatsapp.com/send?phone=55${produtor.marca.whatsapp}`} target='_blank' rel="noreferrer" style={{ textDecorationColor: colors.main_white }}>
                                                <AiOutlineWhatsApp size={20} style={{ color: colors.main_white }} />
                                            </a>
                                            <a href={`https://www.instagram.com/${produtor.marca.instagram}`} target='_blank' rel="noreferrer" style={{ textDecorationColor: colors.main_white }}>
                                                <AiOutlineInstagram size={20} style={{ color: colors.main_white }} />
                                            </a>
                                        </Box>

                                    </Box>

                                </Box>



                            </Grid>

                        )) :
                            <Grid item xs={12} lg={6}>
                                <h3 className='semi-bold' style={{ color: colors.main_blue_dark }}>
                                    Produtores ainda não cadastrados
                                </h3>

                            </Grid>
                        }
                        <Grid item xs={12} lg={12}>

                            <ProdutoresPagination setProdutoresData={(produtor) => setProdutores(produtor)} cidade={'Amparo'} />

                        </Grid>

                    </Grid>

                    <Grid container rowSpacing={5} pb={10} columnGap={'43px'} >
                        <Grid item xs={12}>
                            <h3 className='semi-bold'
                                style={{
                                    padding: '0 0 16px 0',
                                    fontWeight: 550, color: colors.main_blue_dark, borderBottom: `3px solid ${colors.main_blue_dark}`, width: !matches ? '100%' : '280px',
                                    textAlign: matches ? 'left' : 'center'
                                }} >
                                Produtores em Serra Negra
                            </h3>
                        </Grid>

                        {produtores?.serra_negra?.length > 0 ? produtores?.serra_negra?.map((produtor, index) => (
                            <Grid key={index} item xs={12} lg={3} xl={2} >
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    gap: '16px',
                                    border: `1px solid ${colors.main_blue_dark}`,
                                }}>

                                    <img src={produtor?.marca?.logo} alt='Logo da marca' width={matches ? '100%' : '100%'} height={matches ? 286 : 260} style={{
                                        objectFit: 'cover'

                                    }} />

                                    <Box sx={{
                                        backgroundColor: colors.main_blue_dark,
                                        padding: '24px',
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}>
                                        <div>
                                            <a target='_blank' rel="noreferrer" href={produtor?.marca?.site ? `https://${produtor?.marca?.site} ` : `https://www.google.com/maps/dir//${produtor?.propriedade?.logradouro_propriedade}%20-%20${produtor?.propriedade?.cidade_propriedade},%20${produtor?.propriedade?.estado_propriedade},%20${produtor?.propriedade?.cep_propriedade}/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x94c6c6b0a0a0a0a7:0x1b0b0b0b0b0b0b0b?sa=X&ved=2ahUKEwjJ6Z7X2Z7zAhVYIbkGHXZrDZIQ9RcwDHoECBQQBQ`} style={{ textDecorationColor: colors.main_white }}>
                                                <h3 className='white semi-bold'>
                                                    {produtor.propriedade.nome_propriedade}
                                                </h3>
                                            </a>
                                        </div>

                                        <Box sx={{
                                            display: 'flex',
                                            gap: '3px',
                                        }}>
                                            <a href={`https://api.whatsapp.com/send?phone=55${produtor.marca.whatsapp}`} target='_blank' rel="noreferrer" style={{ textDecorationColor: colors.main_white }}>
                                                <AiOutlineWhatsApp size={20} style={{ color: colors.main_white }} />
                                            </a>
                                            <a href={`https://www.instagram.com/${produtor.marca.instagram}`} target='_blank' rel="noreferrer" style={{ textDecorationColor: colors.main_white }}>
                                                <AiOutlineInstagram size={20} style={{ color: colors.main_white }} />
                                            </a>
                                        </Box>

                                    </Box>

                                </Box>
                            </Grid>

                        )) :
                            <Grid item xs={12} lg={6}>
                                <h3 className='semi-bold' style={{ color: colors.main_blue_dark }}>
                                    Produtores ainda não cadastrados
                                </h3>

                            </Grid>
                        }

                        <Grid item xs={12} lg={12}>

                            <ProdutoresPagination setProdutoresData={(produtor) => setProdutores(produtor)} cidade={'Serra Negra'} />

                        </Grid>

                        <Grid item xs={12} lg={6}>

                            <Link to='/todos-produtores' style={{
                                textUnderlineOffset: '8px',
                                textDecorationColor: colors.main_blue_dark,
                                textDecorationThickness: '3px',
                                textDecorationLine: 'underline',

                            }}>

                                <h4 className='semi-bold italic' style={{ color: colors.main_blue_dark, textAlign: matches ? 'start' : 'center' }}>
                                    Todas Cidades
                                </h4>
                            </Link>
                        </Grid>

                    </Grid>

                </Container>

            </Box>

            <Box sx={{
                backgroundColor: colors.main_light_blue,
                position: 'relative',
                minHeight: '300px',
            }}>

                <div className="custom-shape-divider-top-1697630736">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200 0L0 0 598.97 114.72 1200 0z" className="shape-fill"></path>
                    </svg>
                </div>

                <Container maxWidth='xl' id='ig'>

                    <Grid container rowSpacing={5} pb={'100px'} pt={12} >
                        <Grid item xs={12} lg={6}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '40px',

                            }}>
                                <h1 style={{}} className='white bold'>
                                    Sobre a Indicação Geográfica, seu processo e significado
                                </h1>
                                <h4 className='white regular'>
                                    Circuito das Águas Paulista, região conhecida pelas montanhas, ar puro, tranquilidade, destaca-se também, por ser uma região com muitos produtores da bebida genuinamente Brasileira, " Cachaça ".  Crescendo em qualidade, desenvolvendo produtos diferenciados, destaca-se cada vez mais em concursos nacionais e internacionais, com qualidades especiais, características genuínas de cada produtor, agora quer apresentar todos esses valores com a Indicação de procedência. Que orgulho!

                                    <br />
                                    <br />

                                    Em busca de reconhecimento de qualidade e valorização de mercado a APCCAP, em parceria com  Sebrae e Instituto Federal, colocaram um sonho dos produtores em ação. Vencendo obstáculos, atraindo cada vez mais o turismo, mostraremos ao mundo a qualidade das Cachaças do Circuito das Águas Paulista.

                                    <br />
                                    <br />

                                    Deixando o preconceito de lado e brindando ao produto diferenciado, atraente e confiável que produzimos!
                                </h4>

                            </Box>
                        </Grid>
                        <Grid item xs={12} lg={6} >
                            <Box sx={{
                                display: 'flex',
                                justifyContent: matches ? 'flex-end' : 'center',
                            }}>
                                <img src={'https://firebasestorage.googleapis.com/v0/b/igcachaca.appspot.com/o/imagens%2FseloFoto.png?alt=media&token=1fcdaf1c-6d05-49b6-b675-8ebb204460e5'} alt='imagem' width={matches ? 325 : '58%'} />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>

            </Box>

            <Box sx={{
                backgroundColor: colors.main_white,
                position: 'relative',

            }}>

                <div className="custom-shape-divider-top-1697630909">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200 0L0 0 598.97 114.72 1200 0z" className="shape-fill"></path>
                    </svg>
                </div>

                <Container id='associar' maxWidth='xl'>

                    <Box sx={{
                        padding: '160px 0 120px 0',
                    }}>
                        <h1 style={{
                            textAlign: 'center',
                        }} className='black bold'>
                            Você também pode ser um <br /> produtor associado!
                        </h1>

                        <Grid container pt={12} columnSpacing={'228px'} rowSpacing={'64px'} >

                            <Grid item xs={12} lg={6}>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '16px'
                                }}>
                                    <h3 className='semi-bold black'>
                                        Requisitos
                                    </h3>

                                    <h4 className='regular black'>
                                        Estar na região do Circuito das Águas Paulistas, possuir um número de registro no MAPA e uma anotação de responsabilidade técnica (ART).

                                    </h4>

                                </Box>

                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '16px'
                                }}>
                                    <h3 className='semi-bold black'>
                                        Primeira Etapa
                                    </h3>

                                    <h4 className='regular black'>
                                        Preencha o formulário de requerimento e envie os documentos solicitados. Certifique-se de fornecer todas as informações necessárias de forma clara e completa.

                                    </h4>

                                </Box>

                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '16px'
                                }}>
                                    <h3 className='semi-bold black'>
                                        Segunda Etapa
                                    </h3>

                                    <h4 className='regular black'>
                                        Nossos especialistas revisarão os documentos fornecidos e serão realizadas análises do pedido, vistoria e análise laboratorial. Após cada análise, você receberá um laudo com o resultado.

                                    </h4>

                                </Box>

                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '16px'
                                }}>
                                    <h3 className='semi-bold black'>
                                        Última Etapa
                                    </h3>

                                    <h4 className='regular black'>
                                        Após a conclusão bem-sucedida das etapas anteriores, sua solicitação será aprovada, e a credencial de produtor associado será emitida para a assinatura.

                                    </h4>

                                </Box>

                            </Grid>

                        </Grid>




                    </Box>

                </Container>

            </Box>

            <Box sx={{
                backgroundColor: colors.main_white,
                minHeight: '300px',
                paddingBottom: '120px'
            }}>

                <Container maxWidth='md' id='faq'>
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={12} >

                        </Grid>

                        <Grid item sm={12} >
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '40px'
                            }}>
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{
                                    backgroundColor: colors.main_white,
                                    boxShadow: 'none',

                                }}>
                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }} >


                                            <h3 className={expanded !== 'panel1' ? 'black semi-bold' : 'main-purple semi-bold'} >
                                                O processo de associação é gratuito?
                                            </h3>

                                            <div style={{
                                                color: expanded === 'panel1' ? colors.main_purple : colors.main_black,
                                            }}>
                                                {expanded === 'panel1' ? <BiMinus size={20} /> : <BiPlus size={20} />}

                                            </div>

                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Durante o processo de associação o produtor precisará realizar dois pagamentos, ambos durante a segunda etapa. Um é referente a Vistoria da Cadeia Produtiva, realizada por técnicos credenciados. O outro é para a Análise Laboratorial, realizada por um laboratório também credenciado.
                                            <br />
                                            <br />

                                            Após ter seu acesso liberado, o produtor, agora associado, também precisará pagar a mensalidade de sua assinatura. Ele poderá escolher entre planos mensais, semestrais e anuais, e realizar o pagamento via cartão de crédito.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} sx={{
                                    backgroundColor: colors.main_white,
                                    boxShadow: 'none',

                                }}>
                                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }} >


                                            <h3 className={expanded !== 'panel2' ? 'black semi-bold' : 'main-purple semi-bold'} >
                                                Todo o processo ocorre virtualmente ?
                                            </h3>

                                            <div style={{
                                                color: expanded === 'panel2' ? colors.main_purple : colors.main_black,
                                            }}>
                                                {expanded === 'panel2' ? <BiMinus size={20} /> : <BiPlus size={20} />}

                                            </div>

                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Todo processo de associação é realizado de forma virtual, desde o preenchimento do formulário de requerimento até a finalização. O produtor poderá acompanhar o andamento de sua solicitação através de seu perfil na plataforma.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>


                                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} sx={{
                                    backgroundColor: colors.main_white,
                                    boxShadow: 'none',

                                }}>
                                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }} >


                                            <h3 className={expanded !== 'panel3' ? 'black semi-bold' : 'main-purple semi-bold'} >
                                                Posso recorrer caso não seja aprovado ?
                                            </h3>

                                            <div style={{
                                                color: expanded === 'panel3' ? colors.main_purple : colors.main_black,
                                            }}>
                                                {expanded === 'panel3' ? <BiMinus size={20} /> : <BiPlus size={20} />}

                                            </div>

                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Durante um período de 30 dias, é viável submeter uma análise adicional do pedido da analise de documentos. Nesse sentido, o produtor necessitará acessar a área da credencial e formalizar a solicitação de recurso. A equipe da APCCAP compromete-se a realizar uma análise minuciosa do pedido, fornecendo posteriormente um retorno detalhado ao produtor.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} sx={{
                                    backgroundColor: colors.main_white,
                                    boxShadow: 'none',

                                }}>
                                    <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }} >


                                            <h3 className={expanded !== 'panel4' ? 'black semi-bold' : 'main-purple semi-bold'} >
                                                O processo de associação dura quanto tempo ?
                                            </h3>

                                            <div style={{
                                                color: expanded === 'panel4' ? colors.main_purple : colors.main_black,
                                            }}>
                                                {expanded === 'panel4' ? <BiMinus size={20} /> : <BiPlus size={20} />}

                                            </div>

                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            O tempo necessário para concluir o processo de associação pode variar. Normalmente, envolve a revisão de documentos, verificação de qualificações e a emissão de credenciais.Geralmente leva 30 dias.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} sx={{
                                    backgroundColor: colors.main_white,
                                    boxShadow: 'none',

                                }}>
                                    <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }} >


                                            <h3 className={expanded !== 'panel5' ? 'black semi-bold' : 'main-purple semi-bold'} >
                                                Onde posso tirar outras dúvidas ?
                                            </h3>

                                            <div style={{
                                                color: expanded === 'panel5' ? colors.main_purple : colors.main_black,
                                            }}>
                                                {expanded === 'panel5' ? <BiMinus size={20} /> : <BiPlus size={20} />}

                                            </div>

                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Para esclarecer suas dúvidas, sinta-se à vontade para entrar em contato com a APCCAP diretamente ou visite a sede da associação. Estamos aqui para ajudar!
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>



                            </Box>

                        </Grid>



                    </Grid>

                </Container>

            </Box>

            <Box sx={{
                backgroundColor: colors.main_white,
                minHeight: '300px',
                paddingBottom: '120px'
            }}>

                <Container maxWidth='lg'>
                    <Grid container rowSpacing={5}>

                        <Grid item xs={12} lg={6}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '16px'
                            }}>
                                <h1 className='main-purple'>
                                    Você também pode ser um produtor APCCAP.
                                </h1>
                                <h3 className='regular main-purple'>
                                    Descubra a oportunidade de se tornar um produtor da APCCAP e faça parte ativa do nosso compromisso com a excelência. Junte-se a nós na construção de um impacto positivo e significativo em nossa comunidade.
                                </h3>
                            </Box>
                        </Grid>

                        <Grid item xs={12} lg={6} alignSelf={'center'}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: matches ? 'flex-end' : 'center',
                            }}>
                                <button onClick={() => navigate('/registrar')} className='button-purple' style={{
                                    padding: '24px 48px',
                                }}>
                                    Quero me associar <BsArrowUpRight size={25} style={{ verticalAlign: 'bottom' }} />
                                </button>
                            </Box>

                        </Grid>

                    </Grid>

                </Container>

            </Box>

            <Footer />

        </>
    )
}