import { AiFillFacebook as Facebook, AiFillInstagram as Instagram } from 'react-icons/ai'
import { Box, Grid, useMediaQuery } from '@mui/material'
import { a } from 'react-router-dom'
import { RiTwitterXFill } from 'react-icons/ri'
import { colors } from '../../pages/colors';

function Footer() {

  const matches = useMediaQuery('(max-width:600px)');

  return (

    <Box
      component="footer"
      sx={{
        backgroundColor: ' #0F0A70',
        color: '#FAF8F8',
        p: 6,
      }}
    >
      <Grid container spacing={5}>
        <Grid item xs={12} sm={4}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '20px' }}>
            <img src={'https://firebasestorage.googleapis.com/v0/b/igcachaca.appspot.com/o/imagens%2Flogo-title.png?alt=media&token=5879f87f-b857-4cba-9592-54f5470f907d'} alt="logo" width="150px" />
            <Box>
              <a href="https://www.facebook.com/" style={{ color: colors.main_white }}>
                <Facebook />
              </a>
              <a
                href="https://www.instagram.com/"
                style={{ color: colors.main_white }}
                sx={{ pl: 1, pr: 1 }}
              >
                <Instagram />
              </a>
              <a href="https://www.twitter.com/" style={{ color: colors.main_white }}>
                <RiTwitterXFill />
              </a>
            </Box>
            <Box>
              <h4 className='white regular' style={{textAlign:'center'}}>
                Apoio
              </h4>
              <img src={'https://firebasestorage.googleapis.com/v0/b/igcachaca.appspot.com/o/imagens%2Flogo-ifsp-branco.png?alt=media&token=a0f96925-bb2f-4cef-8a16-4b808e304f60'} alt="logo ifsp" width="190px" />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={8} sx={{ display: 'flex', flexDirection: matches ? 'column' : 'row', gap: '20px' }}  >
          <Grid item xs={12} sm={2}>

            <h3 className='white medium' style={{ paddingBottom: '10px' }}>
              Páginas
            </h3>

            {/** 
            <div>
              <a href="/rastreabilidade" variant="body2" color="inherit" style={{ textDecoration: 'none' }}>
                <h5 className='white regular'>Rastreabilidade</h5>
              </a>
            </div>
            */}

            <div>
              <a href="/documentos" variant="body2" color="inherit" style={{ textDecoration: 'none' }}>
                <h5 className='white regular'>Documentos</h5>
              </a>
            </div>

            <div>
              <a href="/festival-cachaca" variant="body2" color="inherit" style={{ textDecoration: 'none' }}>
                <h5 className='white regular'> Festival da Cachaça</h5>
              </a>
            </div>

            <div>
              <a href="/blog" variant="body2" color="inherit" style={{ textDecoration: 'none' }}>
                <h5 className='white regular'> Blog </h5>
              </a>
            </div>


          </Grid>
          <Grid item xs={12} sm={2}>

            <h3 className='white medium' style={{ paddingBottom: '10px' }}>
              Sobre Nós
            </h3>

            <div>
              <a href="/quem-somos#ig" variant="body2" color="inherit" style={{ textDecoration: 'none' }}>
                <h5 className='white regular'> Indicação Geográfica </h5>
              </a>
            </div>

            <div>
              <a href="/quem-somos#produtores" variant="body2" color="inherit" style={{ textDecoration: 'none' }}>
                <h5 className='white regular'>  Nossos Produtores </h5>
              </a>
            </div>

            <div>
              <a href="/quem-somos#associacao" variant="body2" color="inherit" style={{ textDecoration: 'none' }}>
                <h5 className='white regular'> Associção </h5>
              </a>
            </div>

          </Grid>
          <Grid item xs={12} sm={2}>
            <h3 className='white medium' style={{ paddingBottom: '10px' }}>
              Associa-se
            </h3>
            <div>
              <a href="/quem-somos#faq" variant="body2" color="inherit" style={{ textDecoration: 'none' }}>
                <h5 className='white regular'> FAQ </h5>
              </a>
            </div>

            <div>
              <a href="/registrar" variant="body2" color="inherit" style={{ textDecoration: 'none' }}>
                <h5 className='white regular'> Cadastre-se </h5>
              </a>
            </div>
          </Grid>
          <Grid item xs={12} sm={2}>
            <h3 className='white medium' style={{ paddingBottom: '10px' }}>
              Entre em contato
            </h3>


            <Box style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
              <h5 className='white regular'>
                <a target="_blank" rel='noreferrer' href={`https://api.whatsapp.com/send?phone=5511983029282`} style={{ color: colors.main_white, textDecoration: 'none' }}>
                (11) 98302-9282
                </a>
              </h5>

              <h5 className='white regular'>
                <a target="_blank" rel='noreferrer' href={'mailto:appcap@gmail.com'} style={{ color: colors.main_white, textDecoration: 'none' }}>
                  appcap@gmail.com
                </a>
              </h5>

              <h5 className='white regular'>
                <a target="_blank" rel='noreferrer' href='https://www.google.com/maps/dir//Rod.Amparo-Serra%20Negra,Km%20138-%20Almeidas,%20Amparo%20-%20SP,%2013902-800' style={{ color: colors.main_white, textDecoration: 'none' }}>
                  Rod. Amparo-Serra Negra, Km 138 - s/n - Almeidas, Amparo - SP, 13902-800
                </a>
              </h5>

            </Box>
          </Grid>
        </Grid>
      </Grid>


      <Box mt={5}>
        <h5 className=' regular' style={{ textAlign: 'center',color:'#A19ED9' }}>
          {"Todos os direitos reservados ©  Apccap  "}
          {new Date().getFullYear()}
        </h5>
        <h6 className='regular' style={{ textAlign: 'center',marginTop:'10px',color:'#A19ED9' }}>
          Desenvolvido por <a href='https://portal.cmp.ifsp.edu.br/' style={{color:'#A19ED9'}} > Instituto Federal de Educação, Ciência e Tecnologia de São Paulo - IFSP Câmpus Campinas</a>
          </h6>
      </Box>


    </Box>

  )
}

export default Footer