const colors = {
    main_purple : '#140C9F',
    main_blue : '#0F0A70',
    main_blue_dark: '#20244B',
    main_white : '#FAF8F8',
    main_black : '#0B0B0B',
    main_grey:'#E9E9E9',
    main_red:!'#BF232C',
    main_light_blue:'#00AFAB'

}


export { colors }