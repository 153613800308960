import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Typography, Box, CssBaseline, CircularProgress, useMediaQuery, Grid, Card, Avatar, Container, } from '@mui/material';
import Secretary from "./Acesses/Secretario/Secretary"
import Tesoureiro from "./Acesses/Tesoureiro/Tesoureiro"
import President from "./Acesses/Presidente/President"
import Admin from "./Acesses/Admin/Admin"
import { getSubscription } from "../../features/payments/paymentsSlice"
import Conselho from "./Acesses/Conselho/Conselho";
import { CiCircleCheck } from 'react-icons/ci'
import './Style.css'
import { AiOutlineArrowRight, AiOutlineInstagram, AiOutlineWhatsApp } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import { colors } from '../colors'
import { getDocuments } from "../../features/documents/documentsSlice";
import Produtor from "./Acesses/Produtor/Produtor";
import ProdutoresPagination from "../../components/Pagination/Produtores";
import PublicationsPagination from "../../components/Pagination/Publications";


function Dashboard() {


  const { user } = useSelector((state) => state.auth)
  const { isLoading: isLoadingPayments } = useSelector((state) => state.payments)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [produtores, setProdutores] = useState([])
  const [publications, setPublications] = useState([])


  const matches = useMediaQuery('(max-width:800px)')


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const img = new Image();
    img.src = 'https://firebasestorage.googleapis.com/v0/b/igcachaca.appspot.com/o/imagens%2FseloFoto.png?alt=media&token=1fcdaf1c-6d05-49b6-b675-8ebb204460e5'
  }, []);


  useEffect(() => {

    if (user) {

      const userData = {
        cpf: user.dados_pessoais.cpf,
        token: user.token
      }

      dispatch(getSubscription(userData))
      dispatch(getDocuments(user.token))
    }

  }, [user, dispatch])

  if (isLoadingPayments) {

    return <Box sx={
      {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.main_white,
        minHeight: '100vh'
      }
    }>
      <CircularProgress sx={
        {
          marginBottom: '100px',
        }
      } size={100} />
    </Box>
  }



  return (
    <Box style={{
      overflowX: 'hidden',
    }}>
      <CssBaseline />

      {(!user) ? (
        <>
          <Box sx={{
            backgroundColor: colors.main_white,
            padding: '100px 0'
          }}>
            <Container maxWidth='xl'>
              <div className="text-side">
                <h1 style={{ fontWeight: 700 }}>
                  Fique por dentro de tudo sobre a APCCAP
                </h1>

                <div className="rastrear">

                  <Link to={'/quem-somos'} className="link" style={{
                    borderBottom: '3px solid #140C9F',
                    width: 'fit-content',
                  }}>

                    <h3 >
                      Saiba Mais
                    </h3>

                    <div>
                      <CiCircleCheck size={30} />
                    </div>

                  </Link>

                </div>
              </div>
            </Container>
          </Box>

          <Box sx={{
            backgroundColor: '#EE771E',
          }}>
            <Container maxWidth='xl'>
              <section className="festival" onClick={() => navigate('/festival-cachaca')}>
                <div className="texto-festival">
                  <h1>
                    Festival da Cachaça
                  </h1>
                  <a href="https://www.youtube.com/watch?si=e20ykH0j6hwTVF-W&v=palag-0ejF0&feature=youtu.be" target="_blank" rel="noreferrer" style={{
                    color: colors.main_white,
                    fontWeight: 400,
                    fontSize: '20px',

                  }}>
                    Ver edição anterior
                  </a>

                </div>

                <div className="image-festival">
                  <img src={'https://firebasestorage.googleapis.com/v0/b/igcachaca.appspot.com/o/imagens%2Flogo-branco.png?alt=media&token=dbd39fd0-673b-443f-9444-b875960bd145'} alt="logo" className="logo-festival" />
                </div>
              </section>
            </Container>
          </Box>
          <Box sx={{
            backgroundColor: colors.main_white,
            padding: matches ? '40px  0' : '100px 0',
          }}>
            <Container maxWidth='xl'>
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: matches ? 'column' : 'row',
                gap: '40px',
              }}>

                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: matches ? '100%' : '452px',
                  gap: '40px',
                  color: '#140C9F',

                }}>
                  <h1 style={{ fontWeight: 700, fontSize: matches ? '26px' : '' }}>
                    Conheça a história <br />
                    da APCCAP
                  </h1>
                  <h4 style={{ fontWeight: 400, fontSize: matches ? '14px' : '' }}>
                    A APCCAP, fundada em novembro de 2021, representa a união de oito produtores de cachaça de alambique no Circuito das Águas Paulista. Ao longo dos anos, a associação desempenhou um papel vital na divulgação, comercialização e preservação da tradição da cachaça na região.
                  </h4>

                  <h4 style={{ fontWeight: 400, fontSize: matches ? '14px' : '' }}>
                    Por meio de parcerias com entidades como SEBRAE e IFSP, a APCCAP não apenas promove a qualidade do destilado local, mas também busca o reconhecimento internacional e a proteção da Indicação Geográfica, contribuindo para o desenvolvimento econômico da agricultura familiar e impulsionando a economia local.
                  </h4>
                </Box>


                <img src={'https://firebasestorage.googleapis.com/v0/b/igcachaca.appspot.com/o/imagens%2Flogo-apccap.png?alt=media&token=ca21de6c-74fc-450e-acc4-6929efdbbe8c'} alt="associacao" style={{ width: matches ? '50%' : '270px', height: matches ? '50%' : '290px', alignSelf: 'center' }} />


              </Box>

            </Container>
          </Box>


          {/* Produtores */}
          <Box sx={{
            backgroundColor: colors.main_white,
            padding: matches ? '30px  0' : '30px 0',
          }}>
            <Container maxWidth='xl'>
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: matches ? 'column' : 'row',
                gap: '40px',
              }}>

                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: matches ? '100%' : '452px',
                  gap: '40px',
                  color: '#140C9F',
                }}>
                  <h1 style={{ fontWeight: 700, fontSize: matches ? '26px' : '' }}>
                    Nossos produtores estão no Circuito das Águas Paulistas
                  </h1>
                  <h4 style={{ fontWeight: 400, fontSize: matches ? '14px' : '' }}>
                    Localizado no interior de São Paulo, conhecido por suas cidades termais e fontes de águas medicinais, os nove municípios do Circuito das Águas Paulistas oferecem não apenas a tranquilidade das estâncias hidrominerais, mas também uma rica herança histórica e cultural.
                  </h4>

                  <h4 style={{ fontWeight: 400, fontSize: matches ? '14px' : '' }}>
                    O turismo termal e as paisagens deslumbrantes fazem do Circuito das Águas Paulista um destino único, e seu potencial produtivo o torna ainda mais especial.
                  </h4>

                </Box>

                <img src={'https://firebasestorage.googleapis.com/v0/b/igcachaca.appspot.com/o/imagens%2Fcircuito-paulista.png?alt=media&token=2ae8c549-74f2-4f8b-bbda-9fff922349a1'} alt="associacao" style={{ width: matches ? '70%' : '280px', height: matches ? '50%' : '220px', alignSelf: 'center' }} />

              </Box>

            </Container>
          </Box>

          {/* Lojas */}

          <Grid container spacing={2} rowSpacing={'40px'} sx={{ backgroundColor: colors.main_white, padding: '80px 20px 80px 20px' }}>

            <Grid item xs={12} lg={12}>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
                <h1 style={{ color: colors.main_black, fontWeight: 700 }}>
                  Onde Encontrar ?
                </h1>
                <h4 style={{ color: colors.main_black, fontWeight: 400 }}>
                  Confira nossos produtores
                </h4>
              </Box>
            </Grid>

            {produtores?.todos?.map((produtor, index) => (

              <Grid item xs={12} lg={4} xl={2} key={index}>
                <Card sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '25px', padding: '20px', borderRadius: '0', border: '1.7px solid #0B0B0B' }}>

                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '20px',

                  }}>
                    <a target='_blank' rel="noreferrer" href={produtor?.marca?.site ? `https://${produtor?.marca?.site} ` : `https://www.google.com/maps/dir//${produtor?.propriedade?.logradouro_propriedade}%20-%20${produtor?.propriedade?.cidade_propriedade},%20${produtor?.propriedade?.estado_propriedade},%20${produtor?.propriedade?.cep_propriedade}/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x94c6c6b0a0a0a0a7:0x1b0b0b0b0b0b0b0b?sa=X&ved=2ahUKEwjJ6Z7X2Z7zAhVYIbkGHXZrDZIQ9RcwDHoECBQQBQ`}>
                      <h4 style={{ color: colors.main_black, fontWeight: 600 }}>
                        {produtor.propriedade.nome_propriedade.length > 20 ? produtor.propriedade.nome_propriedade.substring(0, 20) + '...' : produtor.propriedade.nome_propriedade}
                      </h4>
                    </a>

                    <Avatar src={produtor?.marca?.logo} alt="avatar" sx={{ width: '45px', height: '45px' }} />

                  </Box>

                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '40px',

                  }}>
                    <h6 style={{ color: colors.main_black, fontWeight: 500 }}>
                      <a href={`https://www.google.com/maps/dir//${produtor?.propriedade?.logradouro_propriedade}%20-%20${produtor?.propriedade?.cidade_propriedade},%20${produtor?.propriedade?.estado_propriedade},%20${produtor?.propriedade?.cep_propriedade}/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x94c6c6b0a0a0a0a7:0x1b0b0b0b0b0b0b0b?sa=X&ved=2ahUKEwjJ6Z7X2Z7zAhVYIbkGHXZrDZIQ9RcwDHoECBQQBQ`} target='_blank' rel="noreferrer"
                        style={{ textDecoration: 'none', color: colors.main_black }}>
                        {produtor?.propriedade?.logradouro_propriedade},  {produtor?.propriedade?.cidade_propriedade} -  {produtor?.propriedade?.estado_propriedade},  {produtor?.propriedade?.cep_propriedade}
                      </a>
                    </h6>

                    <Box sx={{ display: 'flex', gap: '5px', flexDirection: 'column', paddingRight: '10px' }}>
                      <a href={`https://api.whatsapp.com/send?phone=55${produtor.marca.whatsapp}`} target='_blank' rel="noreferrer" style={{ textDecorationColor: colors.main_white }}>
                        <AiOutlineWhatsApp size={20} style={{ color: colors.main_black }} />
                      </a>
                      <a href={`https://www.instagram.com/${produtor.marca.instagram}`} target='_blank' rel="noreferrer" style={{ textDecorationColor: colors.main_black }}>
                        <AiOutlineInstagram size={20} style={{ color: colors.main_black }} />
                      </a>
                    </Box>

                  </Box>


                </Card>
              </Grid>

            ))}

            <Grid item xs={12} lg={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

              <ProdutoresPagination setProdutoresData={(produtor) => setProdutores(produtor)} cidade={'todos'} pages={6} />
            </Grid>


          </Grid>



          {/* Não Associado */}

          <Box sx={{
            backgroundColor: colors.main_white,
            padding: matches ? '30px  0' : '80px 0',
          }}>
            <Container maxWidth='xl'>
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: matches ? 'column' : 'row',
                gap: '40px',
              }}>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: matches ? '100%' : '452px',
                  gap: '40px',
                  color: '#140C9F',
                }}>
                  <h1 style={{ fontWeight: 700, fontSize: matches ? '26px' : '' }}>
                    Você também pode se tornar um produtor da APCCAP
                  </h1>
                  <h4 style={{ fontWeight: 400, fontSize: matches ? '14px' : '' }}>
                    Descubra como você pode contribuir para o nosso compromisso com a excelência e se envolver ativamente na construção de um impacto positivo em nossa comunidade.
                  </h4>
                  <h4 style={{ fontWeight: 400, fontSize: matches ? '14px' : '' }}>
                    Na APCCAP, acreditamos que cada indivíduo tem o potencial de ser um agente de mudança. Ao se tornar um produtor, você se junta a uma comunidade dedicada que valoriza a inovação, a sustentabilidade e o impacto positivo.
                  </h4>

                  <Link to={'/quem-somos#associar'} className="saiba-mais">
                    <h3>
                      Saiba mais
                    </h3>
                  </Link>

                </Box>

                <img src={'https://firebasestorage.googleapis.com/v0/b/igcachaca.appspot.com/o/imagens%2Fprodutor.png?alt=media&token=c493d49e-ed0f-477c-b15e-68cc8310ae5b'} alt="associacao" style={{ width: matches ? '70%' : '275px', height: matches ? '50%' : '350px', alignSelf: 'center' }} />

              </Box>

            </Container>
          </Box>



          {/* Notícias */}
          <Box sx={{
            backgroundColor: colors.main_white,
          }}>
            <Container maxWidth='xl' >
              <Grid container spacing={2} pt={matches && 0} padding={matches ? 0 : '0 52px'} className="noticias">

                <Grid item xs={12} lg={12}>

                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: matches ? 'column' : 'row',
                  }}>

                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '16px',
                      width: matches ? '100%' : '542px',
                    }}>
                      <h1 style={{ fontWeight: 700, fontSize: matches ? '26px' : '', color: colors.main_purple, }}>
                        Acompanhe as notícias
                      </h1>
                      <h4 style={{ fontWeight: 400, fontSize: matches ? '14px' : '', color: colors.main_purple, }}>
                        Mantenha-se informado sobre eventos, conquistas e iniciativas que moldam nosso compromisso com a comunidade.
                      </h4>
                    </Box>

                    {!matches && (<>
                      <Link to='/blog' style={{ textDecoration: 'none' }}>
                        <Box sx={{ display: 'flex', gap: '10px' }} className='ver-mais'>
                          <h3>
                            Ver Mais
                          </h3>
                          <AiOutlineArrowRight style={{ verticalAlign: 'bottom', color: colors.main_purple, }} size={20} />
                        </Box>
                      </Link>
                    </>)}


                  </Box>

                </Grid>

                <Grid

                  item xs={12} lg={12} pt={matches && 0} sx={{

                    '&:hover': {
                      cursor: 'pointer',
                    }
                  }} >
                  <Box sx={{
                    display: 'flex',
                    flexDirection: matches ? 'column' : 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '0px',
                    marginTop: matches ? '-40px' : '0',
                  }}>

                    {publications.map((publication, index) => (
                      <div key={index} onClick={() => navigate(`/blog/${publication._id}`)}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', padding: matches ? '30px' : 0 }}>

                          <img src={publication?.thumbnail.url} alt="associacao" style={{ width: matches ? '100%' : '400px', height: matches ? '205px' : '255px', objectFit: 'cover' }} />

                          <Box sx={{ display: 'flex', gap: '24px', flexDirection: 'column', maxWidth: '400px', padding: '40px 30px' }}>
                            <Box>
                              <Link className="temas" ><h5 >{publication.theme}</h5></Link>
                              <h3 style={{ color: colors.main_black }}>
                                {publication?.title}
                              </h3>
                              
                            <span
                              className='regular black italic'
                      
                            >{publication?.publication_date.split('-')[2].split('T')[0]}/{publication?.publication_date.split('-')[1]}/{publication?.publication_date.split('-')[0]}</span>
                            </Box>

                            <div className='regular black' style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: publication?.description.length > 250 ? publication.description.substring(0, 250) + '...' : publication.description }} />


                          </Box>

                        </Box>
                      </div>

                    ))}



                    {matches && (<>
                      <Link to='/blog' style={{ textDecoration: 'none', paddingBottom: matches ? '0px' : '120px' }}>
                        <Box sx={{ display: 'flex', gap: '10px' }} className='ver-mais'>
                          <h3>
                            Ver Mais
                          </h3>
                          <AiOutlineArrowRight style={{ verticalAlign: 'bottom', color: '#140C9F' }} size={20} />
                        </Box>
                      </Link>
                    </>)}
                  </Box>

                </Grid>



              </Grid>
            </Container>

            <PublicationsPagination setPublicationsData={(p) => setPublications(p)} pages={3} invisible={true} />

          </Box>

          <Footer />

        </>

      ) : (

        <>

          {(user.role === "produtor" || user.role === 'produtor_associado') && (
            <Produtor />
          )}

          {(user.role === "admin") && (
            <Admin />
          )}

          {(user.role === 'secretario' || user.role === 'vice secretario') && (
            <Secretary />
          )}

          {(user.role === 'tesoureiro' || user.role === 'vice tesoureiro') && (
            <Tesoureiro />
          )}

          {(user.role === 'presidente' || user.role === 'vice presidente') && (
            <President />
          )}

          {(user.role === 'conselho') && (
            <Conselho />
          )}

        </>
      )
      }

    </Box >

  )
}

export default Dashboard