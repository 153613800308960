import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga4';

const container = document.getElementById('root');
const root = createRoot(container);

ReactGA.initialize("G-FKZ833Q9C3");
ReactGA.send({ hitType: "pageview", page: window.location.pathname });

root.render(
    <Provider store={store}>
      <App />
    </Provider>
);

reportWebVitals();
